import React from "react";
import Chat from "./chat";
import ChatThreadsHistory from "./chatThreadsHistory";
import ChatTopBar from "./chatTopBar";
import TokensScreen from "../packages/packagesScreen";
import {

  User,
} from "firebase/auth";

interface ChatScreenProps { 
  user:User
  handleSubmitMessage: (message: string) => void;
  handleThreadChange: (message: string) => void;
  submitted:boolean;
  children?: React.ReactNode;

}

const ChatScreen: React.FC<ChatScreenProps> = (props) => {
  const tokens = 30;
 


  return (
<>
      <div className="chat-screen">
        <ChatTopBar tokens={tokens} />
        <div className="chat-screen__row">
          <ChatThreadsHistory handleThreadChange={props.handleThreadChange} name={props.user.displayName?props.user.displayName:props.user.email?props.user.email:""} photoURL={props.user.photoURL?props.user.photoURL:''} />
          <div className="chat-screen__threads-spacer"></div>
          <Chat photoURL={props.user.photoURL?props.user.photoURL:''}  handleSubmitMessage={props.handleSubmitMessage} submitted={props.submitted} >
          {props.children}
          </Chat>
        </div>
      </div>

      <TokensScreen name={props.user.displayName?props.user.displayName:props.user.email?props.user.email:""} />
</>
  );
};

export default ChatScreen;
