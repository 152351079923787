
import { Package } from "./package";

const tokens=30;
const prices =[{"price":10,"id":"1234"}]


export default function Packages() {


  //Get Prices


  return (
    <div className="packages-wrapper">
      <div className="packages-wrapper__tokens">
        <span>Tokens Disponibles: {tokens}</span>
      </div>
      <span>Paquetes</span>
      {prices.map((price) => (
        <Package key={price.id} price={price.price}  />
      ))}
    </div>
  );
}
