import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import "./App.css";
import { app } from "./firebase-setup/firebase";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  User,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  setDoc,
  onSnapshot,
  getDocs,
  Timestamp,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import LandingSpinner from "./components/landing/landing-spinner";
import { reWriteQuery } from "./gemini/transitor1";
import { getContext } from "./gemini/transitor2";
import { getAnswer } from "./gemini/transitor3";
import Articles from "./articles/articulos.json";
import ChatScreen from "./components/chat/chatScreen";
import Landing from "./components/landing/landing";
import { Thread, Message, threadsName } from "./components/utils";
import {
  setActiveThreadMessages,
  setThreads,
  setActiveThreadID,
  setUser,
  setStreamedAnswer,
  setRelevantArticles,
} from "./reducers/settings";
import { RootState } from "./store";
import { useDispatch, useSelector } from "react-redux";
import FeedbackContainer from "./components/chat/feebackContainer";
import { isHistoryRelevant } from "./gemini/transitor0";

// App.tsx

function App() {
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  const providerf = new FacebookAuthProvider();

  const dispatch = useDispatch();
  const activeThreadID = useSelector(
    (state: RootState) => state.settings.activeThreadID
  );
  const threads = useSelector((state: RootState) => state.settings.threads);
  const user = useSelector((state: RootState) => state.settings.user);

  const [activeThread, setActiveThread] = useState<Thread | null>(null);

  const [userMessage, setUserMessage] = useState<Message | null>(null);
  const [reWrite, setReWrite] = useState<string | null>(null);

  const [context, setContext] = useState<{} | null>(null);
  const [newThread, setnewThread] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [elapsedTime, setElapsedTime] = useState<number>(0);

  const [answer, setAnswer] = useState<Message | null>(null);
  const [feedbackSentiment, setFeedbackSentiment] = useState<string | null>(
    null
  );
  const [feedbackInputValue, setFeedbackInputValue] = useState<string>("");
  const [feedback, setFeedback] = useState<string | null>(null);

  async function login(provider: GoogleAuthProvider | FacebookAuthProvider) {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    dispatch(setUser(user));
  }

  // Firestore functions
  const db = getFirestore(app);

  async function createThread() {
    if (!user) throw Error("no user");
    const threadsRef = collection(db, `users/${user.uid}/${threadsName}`);
    const newThread: Thread = {
      createdAt: Timestamp.now(),
      id: user.uid,
    };
    try {
      const docRef = await addDoc(threadsRef, newThread);
      return docRef.id;
    } catch (error) {
      console.error("Error creating thread:", error);
      // Handle error (e.g., display an error message to the user)
      throw error; // Rethrow the error to handle it outside this function if needed
    }
  }

  async function addMessage(threadId: string, message: Message) {
    if (!user) throw Error("no user");
    const messageRef = doc(
      db,
      `users/${user.uid}/${threadsName}/${threadId}/messages/${message.timestamp}`
    );
    try {
      // set loading state (if applicable)
      // setIsLoading(true);

      await setDoc(messageRef, message);
    } catch (error) {
      console.error("Error adding message:", error);
      // Handle error (e.g., display an error message to the user)
    } finally {
      // Clear loading state (if applicable)
      // setIsLoading(false);
      console.log("added: ", threadId, message.role, message.content);
      if (message.role === "user") {
        setUserMessage(message);
      }
      if (message.role === "transitor") {
        setAnswer(message);
      }
    }
  }

  async function handleSubmitMessage(messageContent: string) {
    setSubmitted(true);
    let threadId = activeThreadID;

    if (!threadId) {
      threadId = await createThread();

      dispatch(setActiveThreadID(threadId));
    } else {
      setnewThread(false);
    }
    const message: Message = {
      role: "user",
      content: messageContent,
      timestamp: Timestamp.now(),
    };

    try {
      await addMessage(threadId, message); // Pass threadId instead of activeThreadID
    } catch (error) {
      console.error("Error adding to thread:", error);
      // Handle error (e.g., display an error message to the user)
      return;
    }
  }

  async function saveContextToFirestore(messageContent: string) {
    const message: Message = {
      role: "transitor2",
      content: messageContent,
      timestamp: Timestamp.now(),
    };

    try {
      if (!activeThreadID) throw Error("no active threadID");
      await addMessage(activeThreadID, message); // Pass threadId instead of activeThreadID
    } catch (error) {
      console.error("Error adding context to thread:", error);
      // Handle error (e.g., display an error message to the user)
      return;
    }
  }

  async function saveRewriteToFirestore(messageContent: string) {
    console.log("saving rewrite to firestore", messageContent);
    const message: Message = {
      role: "transitor1",
      content: messageContent,
      timestamp: Timestamp.now(),
    };

    try {
      if (!activeThreadID) throw Error("no active threadID");
      await addMessage(activeThreadID, message); // Pass threadId instead of activeThreadID
    } catch (error) {
      console.error("Error adding context to thread:", error);
      // Handle error (e.g., display an error message to the user)
      return;
    }
  }

  async function saveAnswerToFirestore(answer: string) {
    const message: Message = {
      role: "transitor",
      content: answer,
      timestamp: Timestamp.now(),
    };

    try {
      if (!activeThreadID) throw Error("no active threadID");
      await addMessage(activeThreadID, message); // Pass threadId instead of activeThreadID
    } catch (error) {
      console.error("Error saving answer:", error);
      // Handle error (e.g., display an error message to the user)
      return;
    }
  }

  const saveFeedbackToFirestore = async (feedback: string) => {
    if (!answer) throw new Error("answer is null");

    // Update the feedback in the state
    setFeedback(feedback);

    const message: Message = {
      role: "userFeedback",
      content: feedback,
      timestamp: Timestamp.now(),
    };

    try {
      if (!activeThreadID) throw Error("no active threadID");
      await addMessage(activeThreadID, message); // Pass threadId instead of activeThreadID
    } catch (error) {
      console.error("Error adding to thread:", error);
      // Handle error (e.g., display an error message to the user)
      return;
    }
  };

  const saveErrorToFirestore = async (error: string) => {
    let role = "error";

    if (
      error === "Error: rewrittenQuery is null" ||
      error === "Error: Exceeded context retry count" ||
      error === "Error: Exceeded answer retry count" ||
      error === "Error: Question out of scope"
    ) {
      role = "error visible";
    }
    const message: Message = {
      role: role,
      content: error,
      timestamp: Timestamp.now(),
    };

    try {
      if (!activeThreadID) throw Error("no active threadID");
      await addMessage(activeThreadID, message); // Pass threadId instead of activeThreadID
    } catch (error) {
      console.error("Error adding error to thread:", error);
      // Handle error (e.g., display an error message to the user)
      return handleReset();
    }
    return handleReset();
  };

  const handleThreadChange = (threadID: string) => {
    dispatch(setActiveThreadID(threadID));
    setFeedback(null);
    setFeedbackSentiment(null);
    setFeedbackInputValue("");
  };

  const handleReset = () => {
    setSubmitted(false);
    setUserMessage(null);
    setReWrite(null);

    setContext(null);

    setElapsedTime(0);
    dispatch(setStreamedAnswer(null));

    setAnswer(null);
    setFeedback(null);
    setFeedbackSentiment(null);
    setFeedbackInputValue("");
    return console.log("handled reset");
  };
  //  0 listen for auth changes and update user in local state
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(setUser(user));
      } else {
        dispatch(setUser(null));
      }
    });
  }, [auth]);

  //  1 if user exists get user's threads
  useEffect(() => {
    console.log("threads user:", user?.displayName);
    console.log("threads :", threads);
    let tempThreads: Thread[] | undefined;
    async function fetchAllThreads() {
      if (!user) throw Error("no user");
      const threadsRef = collection(db, `users/${user.uid}/${threadsName}`);
      // const querySnapshot = await getDocs(threadsRef);

      const q = query(threadsRef, orderBy("createdAt", "desc"), limit(20));
      const querySnapshot = await getDocs(q);
      const threadsData: Thread[] = [];
      querySnapshot.forEach((doc) => {
        const threadData = doc.data() as Thread;
        threadsData.push({
          ...threadData,
          id: doc.id,
        });
      });
      tempThreads = threadsData;
    }

    if (user && threads?.length === 0) {
      console.log("getting threads");
      fetchAllThreads()
        .then(() => {
          if (!tempThreads) throw new Error("no threads!");
          dispatch(setThreads(tempThreads));

          console.log("fetched threads: ", tempThreads);
        })
        .catch((error) => {
          console.error("Error fetching threads:", error);
          return undefined;
        });
    }
  }, [user, threads]);

  //  3 if user and active thread ID exists get active thread data
  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const fetchThread = async () => {
      if (!activeThreadID || !user) return;

      const threadRef = doc(
        db,
        `users/${user.uid}/${threadsName}/${activeThreadID}`
      );
      const messagesRef = collection(threadRef, "messages");

      unsubscribe = onSnapshot(messagesRef, (snapshot) => {
        const messages: Message[] = [];
        snapshot.forEach((doc) => {
          const messageData = doc.data() as Message;
          messages.push(messageData);
        });

        const threadData = {
          createdAt: activeThread?.createdAt || Timestamp.now(),
          messages: messages,
          id: user.uid,
        };
        setActiveThread(threadData);
        dispatch(setActiveThreadMessages(threadData.messages));
      });
    };

    fetchThread();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [activeThreadID, user]);

  //  3 if query is submitted start timer until answer

  useEffect(() => {
    let tempElapsed = 0;
    if (!submitted && elapsedTime !== 0) {
      setElapsedTime(0);
    }
    const interval = setInterval(() => {
      if (submitted && !answer) {
        tempElapsed = elapsedTime + 100;

        setElapsedTime(tempElapsed);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [submitted, elapsedTime, answer]);

  //  4 if query is submited check it its a new thread, if new thread carry on, if not call isHistoryRelevant
  useEffect(() => {
    let rewrittenQuery: string | undefined;
   

    let isHR: string | undefined;
    let history: string | undefined;

    async function handleHistory() {
      console.log("filter History");
      const filteredMessages = activeThread?.messages?.filter(
        (message) =>
          message.role === "transitor" ||
          message.role === "transitor2" ||
          message.role === "user"
      );
      history = filteredMessages
        ?.map(
          (message) =>
            `${
              message.role === "user"
                ? "input: "
                : message.role === "transitor2"
                ? "Basa tu respuesta en los siguientes articulos:"
                : message.role === "transitor"
                ? "output: "
                : "remove"
            } ${ message.role === "transitor2"?message.content.split(',').map(Number).map((articleNumber)=>{return`Articulo ${articleNumber}-${Articles[articleNumber - 1].content}`}):message.content}`
        )
        .join("\n");
      console.log("check History: ", history);

  

  

    
      if (!history) throw new Error("history is null");
      isHR = await isHistoryRelevant(history);
    }

    async function handleRewrite() {
      console.log("handle rewrite");
      if (!userMessage) throw new Error("userMessage is null");

      try {
    
          rewrittenQuery = await reWriteQuery(userMessage.content);
       

        if (!rewrittenQuery) throw new Error("rewrittenQuery is null");
      } catch (error) {
        console.error("Error rewriting:", error);
        rewrittenQuery = undefined;
        return;
      }
    }

    if ((userMessage && !reWrite && newThread)) {
      console.log("handle rewrite");
      try {
        handleRewrite()
          .then(() => {
            if (!rewrittenQuery) throw new Error("rewrittenQuery is null");
            setReWrite(rewrittenQuery);
            saveRewriteToFirestore(rewrittenQuery);
          })
          .catch((error) => {
            console.error("Error handleRewrite:", error);
            saveErrorToFirestore(error.toString());
            return undefined;
          });
      } catch (error) {
        console.error("Error handling rewrite:", error);
        return;
        // Handle error (e.g., display an error message to the user)
      }
    }

    if (userMessage && !reWrite && !newThread && !isHR) {
      console.log("check history");
      try {
        handleHistory()
          .then(() => {
            if (!isHR) throw new Error("isHR is null");
            console.log("isHR!! :",isHR)

            if(isHR!=="[]"){  
              dispatch(setStreamedAnswer(isHR));
              saveAnswerToFirestore(isHR);
              handleReset();}
              else{
                try {
                  handleRewrite()
                    .then(() => {
                      if (!rewrittenQuery) throw new Error("rewrittenQuery is null");
                      setReWrite(rewrittenQuery);
                      saveRewriteToFirestore(rewrittenQuery);
                    })
                    .catch((error) => {
                      console.error("Error handleRewrite:", error);
                      saveErrorToFirestore(error.toString());
                      return undefined;
                    });
                } catch (error) {
                  console.error("Error handling rewrite:", error);
                  return;
                  // Handle error (e.g., display an error message to the user)
                }
              }
          
          
          })
          .catch((error) => {
            console.error("Error handling history:", error);
            saveErrorToFirestore(error.toString());
            return undefined;
          });
      } catch (error) {
        console.error("Error handling history:", error);
        return;
        // Handle error (e.g., display an error message to the user)
      }

    }

   

  }, [userMessage, context]);

  // 5 if query has been rewritten get context
  useEffect(() => {
    let tempRelevantArticles: number[] | undefined;
    let tempContext: {} | undefined;
    let retryCount = 0; // Add retry count

    async function handleContext(): Promise<void> {
      return new Promise(async (resolve, reject) => {
        if (!reWrite) reject(new Error("rewrite is null"));

        const retry = () => {
          if (retryCount < 3) {
            const retryDelay = 2 ** retryCount * 1000; // Exponential backoff: 1s, 2s, 4s
            retryCount++;
            console.log("Retrying handleContext in", retryDelay, "ms");
            setTimeout(() => {
              handleContext().then(resolve).catch(reject);
            }, retryDelay); // Retry after delay
          } else {
            reject(new Error("Exceeded context retry count"));
          }
        };

        try {
          if (!reWrite) throw new Error("rewrite is null");
          tempRelevantArticles = await getContext(reWrite);
          if (tempRelevantArticles?.length === 0) {
            reject(new Error("Question out of scope"));
            //throw new Error("Question out of scope");
          }
          if (!tempRelevantArticles)
            throw new Error("relevant articles is null");

          let articlesObject: { [key: number]: string } = {};
          dispatch(setRelevantArticles(tempRelevantArticles));
          tempRelevantArticles.forEach((item, index) => {
            articlesObject[item] = Articles[item - 1].content;
          });
          tempContext = articlesObject;

          if (!tempContext) throw new Error("context is null");

          resolve();
        } catch (error) {
          console.error("Error contextualizing:", error);
          tempContext = undefined;

          if (error === "Error: context is null") {
            retry();
          }
        }
      });
    }

    if (userMessage && reWrite && !context) {
      setContext("Loading context");
      console.log("getting context");
      try {
        handleContext()
          .then(() => {
            if (!tempContext)
              throw new Error("tried 3 times and context is still null");
            if (!tempRelevantArticles)
              throw new Error("then relevant articles is null");
            console.log("context: ", tempContext);
            setContext(tempContext);
            saveContextToFirestore(tempRelevantArticles.toString());
          })
          .catch((error) => {
            console.error("Error handling context:", error);
            saveErrorToFirestore(error.toString());
          });
      } catch (error) {
        console.error("Error handling context:", error);
        setContext(null);
        tempContext = undefined;
        return;
        // Handle error (e.g., display an error message to the user)
      }
    }
  }, [userMessage, context, reWrite]);

  //  6 if context has been loaded get answer
  useEffect(() => {
    let tempAnswer: string | undefined;
    let retryCount = 0; // Add retry count

    async function handleAnswer(): Promise<void> {
      return new Promise(async (resolve, reject) => {
        const retry = () => {
          if (retryCount < 3) {
            const retryDelay = 2 ** retryCount * 1000; // Exponential backoff: 1s, 2s, 4s
            retryCount++;
            console.log("Retrying handleAnswer in", retryDelay, "ms");
            setTimeout(() => {
              handleAnswer().then(resolve).catch(reject);
            }, retryDelay); // Retry after delay
          } else {
            reject(new Error("Exceeded answer retry count"));
          }
        };

        try {
          if (!reWrite) throw new Error("userMessage is null");
          if (!context) throw new Error("context is null");
          tempAnswer = await getAnswer(reWrite, context);
          if (!tempAnswer) throw new Error("answer is null");
          dispatch(setStreamedAnswer(tempAnswer));

          resolve();
        } catch (error) {
          console.error("Error answering:", error);
          tempAnswer = undefined;
          retry();
        }
      });
    }

    if (userMessage && reWrite && context && context !== "Loading context") {
      try {
        handleAnswer()
          .then(() => {
            if (!tempAnswer) throw new Error("answer is null");
            console.log("saving answer to firestore", tempAnswer);

            saveAnswerToFirestore(tempAnswer.toString());
            handleReset();
          })
          .catch((error) => {
            console.error("Error handling answer:", error);
            saveErrorToFirestore(error.toString());
            return undefined;
          });
      } catch (error) {
        console.error("Error handling answer:", error);
        return;
      }
    }
  }, [context]);

  return (
    <div className="App">
      {(user?.uid === "RbDtDQ4NijXri129KjFyduGDt7f1" ||
        "HYAhidJdvZYhL190QEJwgcz18iM2") && (
        <div className="elapsed-time-container">
          elapsed:{elapsedTime ? (elapsedTime / 1000).toFixed(1) : "0"}
        </div>
      )}
      <LandingSpinner loaded={user ? true : false} />
      {user ? (
        <ChatScreen
          user={user}
          handleSubmitMessage={handleSubmitMessage}
          submitted={submitted}
          handleThreadChange={handleThreadChange}
        >
          {user && answer && !feedback && (
            <FeedbackContainer
              saveFeedbackToFirestore={saveFeedbackToFirestore}
              feedbackInputValue={feedbackInputValue}
              setFeedbackInputValue={setFeedbackInputValue}
              feedbackSentiment={feedbackSentiment || ""}
              setFeedbackSentiment={setFeedbackSentiment}
            />
          )}
        </ChatScreen>
      ) : (
        <>
          <Landing
            loginG={() => login(provider)}
            loginF={() => login(providerf)}
          />
        </>
      )}
    </div>
  );
}

export default App;
