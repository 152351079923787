import { useState, ChangeEvent, FormEvent, useEffect } from "react";
import Dictaphone from "./speechRecognition";
import SpeechRecognition, {
  useSpeechRecognition,
  ListeningOptions,
} from "react-speech-recognition";

interface TestProps {
  handleSubmitMessage: (message: string) => void;
  submitted: boolean;
}

const Chat = ({ handleSubmitMessage, submitted }: TestProps) => {
  const [value, setValue] = useState("");

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    //  alert('A query was submitted: ' + value);
    event.preventDefault();
    handleSubmitMessage(value);
    setValue("");
  };

  const startListeningHandler = () => {
    SpeechRecognition.startListening({ language: "es-MX" });
 
  };

  const stopListeningHandler = () => {
    SpeechRecognition.stopListening();
  };

  const resetTranscriptHandler = () => {
    resetTranscript();
  };


  useEffect(() => {
  if(listening){
    console.log("listening")
    setValue(transcript);
  }
  }, [listening, transcript]);

  return (
    <>
 
      <form onSubmit={handleSubmit} className={"chat-user-input__container"}>
      {(browserSupportsSpeechRecognition) && (
          <button
            className={`chat-user-input__send ${listening ? 'listening' : ' '}`}
            onClick={startListeningHandler}
            type="button"
          >
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-400q-50 0-85-35t-35-85v-240q0-50 35-85t85-35q50 0 85 35t35 85v240q0 50-35 85t-85 35Zm0-240Zm-40 520v-123q-104-14-172-93t-68-184h80q0 83 58.5 141.5T480-320q83 0 141.5-58.5T680-520h80q0 105-68 184t-172 93v123h-80Zm40-360q17 0 28.5-11.5T520-520v-240q0-17-11.5-28.5T480-800q-17 0-28.5 11.5T440-760v240q0 17 11.5 28.5T480-480Z"/></svg>
          </button>
        )}
        <input
          autoFocus
          placeholder="Escribe tu pregunta."
          className="chat-user-input__text-box"
          value={value}
          onChange={handleChange}
        />
        
        {value&&<button
          className="chat-user-input__send"
          type="submit"
          disabled={submitted}
          onClick={()=>{console.log("value:",value)}}
        >
          {submitted ? "•••" : "↑"}
        </button>}
      </form>
    </>
  );
};

export default Chat;
