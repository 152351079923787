import { Timestamp } from 'firebase/firestore';

// assign gemini API endpoint according to development mode (dev/prod).
export function geminiProdApiEndpoint(isProdMode: boolean = true): string {
  const geminiFunctionUrl = isProdMode ? 'https://us-central1-transito-ai-test-01.cloudfunctions.net/gemini' : 'http://127.0.0.1:5001/transito-ai-test-01/us-central1/gemini';
  return geminiFunctionUrl;
}
export const cloudFunctionUrl = geminiProdApiEndpoint(true);
export const threadsName="threadsFF";

export interface Message {
    role: string;
    content: string;
    timestamp:Timestamp;

  }


  
  export interface Thread {
    createdAt: Timestamp;
    messages?: Message[];
    id:string;
  }
  
  
  



export const articlePages = {
  "Artículo 2.": [
    1,
    27,
    28,
    30,
    31,
    32,
    33
  ],
  "Artículo 1.": [
    1,
    16,
    19,
    22,
    23,
    24,
    25,
    26
  ],
  "Artículo 3.": [
    2,
    34,
    38,
    40,
    42,
    43,
    45,
    48
  ],
  "Artículo 6.": [
    9,
    65,
    66,
    67,
    70,
    72
  ],
  "Artículo 7.": [
    11,
    72
  ],
  "Artículo 4.": [
    2,
    48,
    50,
    51,
    52,
    54,
    55,
    56,
    57
  ],
  "Artículo 5.": [
    8,
    57,
    58,
    59,
    60,
    61,
    62,
    64
  ],
  "Artículo 9.": [
    14
  ],
  "Artículo 11.": [
    19
  ],
  "Artículo 8.": [
    11
  ],
  "Artículo 10.": [
    16
  ],
  "Artículo 14.": [
    24
  ],
  "Artículo 13.": [
    23
  ],
  "Artículo 12.": [
    22
  ],
  "Artículo 16.": [
    26
  ],
  "Artículo 15.": [
    25
  ],
  "Artículo 17.": [
    26
  ],
  "Artículo 19.": [
    26
  ],
  "Artículo 18.": [
    26
  ],
  "Artículo 20.": [
    27
  ],
  "Artículo 21.": [
    27
  ],
  "Artículo 22.": [
    28
  ],
  "Artículo 26.": [
    32
  ],
  "Artículo 24.": [
    30
  ],
  "Artículo 23.": [
    30
  ],
  "Artículo 25.": [
    31
  ],
  "Artículo 27.": [
    32
  ],
  "Artículo 29.": [
    33
  ],
  "Artículo 28.": [
    33
  ],
  "Artículo 30.": [
    34
  ],
  "Artículo 31.": [
    38
  ],
  "Artículo 33.": [
    38
  ],
  "Artículo 32.": [
    38
  ],
  "Artículo 35.": [
    42
  ],
  "Artículo 36.": [
    42
  ],
  "Artículo 34.": [
    40
  ],
  "Artículo 40.": [
    48
  ],
  "Artículo 37.": [
    43
  ],
  "Artículo 38.": [
    45
  ],
  "Artículo 39.": [
    48
  ],
  "Artículo 41.": [
    50
  ],
  "Artículo 45.": [
    54
  ],
  "Artículo 42.": [
    51
  ],
  "Artículo 44.": [
    52
  ],
  "Artículo 43.": [
    51
  ],
  "Artículo 48.": [
    56
  ],
  "Artículo 47.": [
    56
  ],
  "Artículo 50.": [
    57
  ],
  "Artículo 46.": [
    55
  ],
  "Artículo 51.": [
    58
  ],
  "Artículo 53.": [
    60
  ],
  "Artículo 52.": [
    59
  ],
  "Artículo 49.": [
    57
  ],
  "Artículo 56.": [
    62
  ],
  "Artículo 58.": [
    62
  ],
  "Artículo 54.": [
    60
  ],
  "Artículo 57.": [
    62
  ],
  "Artículo 59.": [
    64
  ],
  "Artículo 55.": [
    61
  ],
  "Artículo 64.": [
    67
  ],
  "Artículo 66.": [
    70
  ],
  "Artículo 62.": [
    66
  ],
  "Artículo 60.": [
    65
  ],
  "Artículo 63.": [
    67
  ],
  "Artículo 70.": [
    72
  ],
  "Artículo 61.": [
    66
  ],
  "Artículo 67.": [
    70
  ],
  "Artículo 68.": [
    72
  ],
  "Artículo 65.": [
    70
  ],
  "Artículo 69.": [
    72
  ]
}
