import { ChangeEvent, FormEventHandler, useRef, useState } from "react";

import "./landing.css";
import mockup from "../../images/mockupBG.webp";
import mockup1 from "../../images/mockup-dialog1.webp";
import mockup2 from "../../images/mockup-dialog2.webp";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface TransitorProps {
  loginG: () => Promise<void>;
  loginF: () => Promise<void>;
}

const Landing = ({ loginG, loginF }: TransitorProps) => {
  const signInSection = useRef<null | HTMLDivElement>(null);

  const password = useSelector(
    (state: RootState) => state.settings.password
  );

  const [value, setValue] = useState("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);

  }

  return (
    <div className="landing-wrapper">

    <div className="sign-in__wrapper">
      <div className="sign-in__top-bar">
        <div className="sign-in__logo"><div id="logo">
          <svg
            id="transitor-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 107.3 114"
          >
            <path d="m30.54,94.9c0,1.75,1.42,3.18,3.18,3.18h32.68c1.75,0,3.18-1.42,3.18-3.18s-1.42-3.18-3.18-3.18h-32.68c-1.75,0-3.18,1.42-3.18,3.18Z" />
            <circle cx="30.54" cy="77.07" r="7.62" />
            <circle cx="69.58" cy="77.07" r="7.62" />
            <path d="m97.58,64.42h-4.96c-.18,0-.36.02-.53.06v-.06h-4.01v36.76c0,4.86-3.95,8.81-8.81,8.81H20.84c-4.86,0-8.81-3.95-8.81-8.81v-44.91c0-4.86,3.95-8.81,8.81-8.81v-.78c-1.18-.65-1.93-1.77-2.24-3.02-6,1.07-10.58,6.31-10.58,12.61v8.21c-.17-.04-.35-.06-.53-.06H2.54c-1.4,0-2.54,1.14-2.54,2.54v20.23c0,1.4,1.14,2.54,2.54,2.54h4.96c.18,0,.36-.02.53-.06v11.52c0,7.07,5.75,12.82,12.82,12.82h58.43c7.07,0,12.82-5.75,12.82-12.82v-11.52c.17.04.35.06.53.06h4.96c1.4,0,2.54-1.14,2.54-2.54v-20.23c0-1.4-1.14-2.54-2.54-2.54Z" />
            <path d="m69.57,2.17c-29.62-6.67-44.89,3.6-46.32,9.95-1.43,6.35,4.79,9.97,4.79,9.97l-.64,12.14c-.28.03-.56.05-.84.08-1.07.11-2.05.65-2.72,1.5-.67.84-.97,1.92-.84,2.99.26,2.06,3.09,12.7,33.9,19.64,8.03,1.81,15.1,2.72,21,2.72,13.24,0,17.16-4.55,18.05-5.94.58-.91.77-2.01.52-3.06-.24-1.05-.9-1.96-1.82-2.52-.24-.15-.49-.29-.73-.44l4.63-11.24s7.17-.61,8.6-6.95c1.43-6.35-7.98-22.17-37.59-28.83Zm-12.77,12.53l10.16-.94,8.78,5.21c-3.37,14.96-13.42,15.41-13.42,15.41,0,0-8.89-4.71-5.52-19.67Zm-18.45,21.79v1.19-1.19s0,0,0,0Zm39.56,20.64c-4.95,0-11.5-.69-20.12-2.63-29.62-6.67-30.8-16.21-30.8-16.21,4.06-.43,7.87-.6,11.36-.6,13.79,0,22.62,2.7,22.62,2.7,0,0,14.34,2.12,31.6,12.67,0,0-2.6,4.08-14.66,4.08Z" />
          </svg></div>
          < span  className="roboto-medium"> TRANSITO.PRO</span>
        </div>

        <a href="#aboutUs" className="about-us-link">Acerca de nosotros</a>
      </div>
      <div className="sign-in__hero-wrapper">
        <div className="sign-in__header">
          <h1 className="sign-in__copy-medium">
            Conversa con un agente virtual entrenado con el reglamento de
            tránsito de la CDMX.
          </h1>
          <span className="sign-in__copy-small">
            Conoce tus derechos, aclara dudas, valida números de placa y más.
            Respuesta Inmediata.
          </span>
          <button onClick={loginG} className="create-account__link  roboto-medium">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2443"
              height="2500"
              preserveAspectRatio="xMidYMid"
              viewBox="0 0 256 262"
              id="google"
            >
              <path
                fill="#4285F4"
                d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
              ></path>
              <path
                fill="#34A853"
                d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
              ></path>
              <path
                fill="#FBBC05"
                d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
              ></path>
              <path
                fill="#EB4335"
                d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
              ></path>
            </svg>
            Continuar con Google
          </button>
          <button onClick={loginF} className="create-account__link roboto-medium">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="facebook"
            >
              <path d="M20.9,2H3.1A1.1,1.1,0,0,0,2,3.1V20.9A1.1,1.1,0,0,0,3.1,22h9.58V14.25h-2.6v-3h2.6V9a3.64,3.64,0,0,1,3.88-4,20.26,20.26,0,0,1,2.33.12v2.7H17.3c-1.26,0-1.5.6-1.5,1.47v1.93h3l-.39,3H15.8V22h5.1A1.1,1.1,0,0,0,22,20.9V3.1A1.1,1.1,0,0,0,20.9,2Z"></path>
            </svg>
            Continuar con Facebook
          </button>
        </div>
        <div className="mockup-wrapper">
          <img
            className="mockup-bg"
            src={mockup}
            alt="mockup"
            width={256}
            height={454}
          />
          <img
            className="mockup-dialog one"
            src={mockup1}
            alt="mockup"
            width={256}
            height={454}
          />
          <img
            className="mockup-dialog two"
            src={mockup2}
            alt="mockup"
            width={256}
            height={454}
          />
        </div>
      </div>
     
    </div>
    <div className="about-us-wrapper" id="aboutUs">
    <div className="about-us-column">
    <h2 className="sign-in__copy-medium">
            Acerca de nosotros
          </h2>
       <p>¡Bienvenidos a Transito Pro, donde encontrarás respuestas claras y precisas a todas tus preguntas sobre tránsito y vialidad en la Ciudad de México!
       </p><p>
Sabemos lo frustrante que puede ser navegar por el mar de información confusa y contradictoria que existe en internet cuando buscas respuestas a tus dudas de tránsito. Además, leer directamente los reglamentos puede ser una tarea tediosa y abrumadora debido al lenguaje técnico y legal utilizado.
</p><p>
Es por eso que hemos creado este servicio único, donde podrás chatear con nuestra inteligencia artificial especializada en reglamentos de tránsito. Nuestra IA tiene acceso directo a la información más actualizada y relevante, lo que te permitirá obtener respuestas precisas y fundamentadas en los artículos específicos que abordan tu pregunta. 
Ya no tendrás que conformarte con respuestas vagas o incompletas. Nuestro chat interactivo te brinda la oportunidad de profundizar en el tema y obtener la información que necesitas de manera sencilla y amigable. Estamos aquí para ayudarte a resolver todas tus dudas sobre tránsito y vialidad en la Ciudad de México, ¡así que no dudes en preguntarnos lo que necesites saber!
</p>
      </div>
      </div>
    </div>
  );
};
export default Landing;
