import { collection, doc, getFirestore, onSnapshot } from "firebase/firestore";
import "./chat.css";
import { app } from "../../firebase-setup/firebase";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Message, threadsName } from "../utils";



export default function ThreadEntry({ date, id }: { date: string, id: string }) {
    const db = getFirestore(app);
    const user = useSelector((state: RootState) => state.settings.user);
    const [question, setQuestion] = useState<string>("");


    useEffect(() => {
      let unsubscribe: (() => void) | undefined;
  
      const fetchThread = async () => {
        if ( !user) return;
  
        const threadRef = doc(db, `users/${user.uid}/${threadsName}/${id}`);
        const messagesRef = collection(threadRef, "messages");
  
        unsubscribe = onSnapshot(messagesRef, (snapshot) => {
       
        
  setQuestion(snapshot.docs[0].data().content)
     
         console.log("thread messages: ",  snapshot.docs[0].data());
        });
      };
  
      fetchThread();
  
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }, [db, id, user]);
  

  return (
    <>
          <span className="fecha">{date}</span>
 <div className="truncate">{question}</div> 
    </>
  );
}