import { createSlice, } from "@reduxjs/toolkit";
import { Thread } from "../components/utils";
import {

  User,
} from "firebase/auth";

// Define the initial state for your game
const initialState = {
  user:null as User |null,
  activeThreadID: null as string | null,
  activeThreadMessages:[],
  threads: [] as Thread[] | undefined,
  historyOpen: false,
  packagesOpen: false,
  pdfOpen: false,
  pdfPage:1,
  relevantArticles:[],
  streamedAnswer: null as string|null,
  password:"no1else"

};

// Create a slice of the game state
const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setUser:(state, action)=>{
      state.user = action.payload;
    },
    setActiveThreadID: (state, action) => {
      state.activeThreadID = action.payload;

    },
    setActiveThreadMessages: (state, action) => {
      state.activeThreadMessages = action.payload;

    },
    setThreads: (state, action) => {
      state.threads = action.payload;

    },
    toggleHistoryOpen: (state) => {
      state.historyOpen = !state.historyOpen;


    },
    togglePackagesOpen: (state) => {
      state.packagesOpen = !state.packagesOpen;


    },
    setPDFOpen: (state, action) => {
      state.pdfOpen = action.payload;


    },
    setPDFPage: (state, action) => {
      state.pdfPage = action.payload;


    },
    setRelevantArticles: (state, action) => {
      state.relevantArticles = action.payload;


    },
    
    setStreamedAnswer: (state, action) => {
      state.streamedAnswer = action.payload;


    },

  },
});

// Export the reducer and actions
export const {
  setUser, setActiveThreadID,setActiveThreadMessages,setThreads, toggleHistoryOpen, togglePackagesOpen, setPDFOpen, setPDFPage, setStreamedAnswer, setRelevantArticles
} = settingsSlice.actions;
export default settingsSlice.reducer;
