
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { toggleHistoryOpen, togglePackagesOpen } from "../../reducers/settings";

interface ChatTopBarProps {
    tokens: number | null
}
export default function ChatTopBar({
    tokens
}: ChatTopBarProps) {
    const dispatch = useDispatch()

    const historyOpen = useSelector((state: RootState) => state.settings.historyOpen);

    return (
        <div className="chat-screen__top-bar">
            <div className="chat-screen__top-bar-left">
                <button className="close-button" onClick={() => dispatch(toggleHistoryOpen())}>{historyOpen ? "✕" : "☰"}</button>
                < span  className="roboto-medium"> TRANSITO.PRO</span></div>
          {/*  <button className="chat-screen__top-bar-tokens-button" onClick={() => dispatch(togglePackagesOpen())}>Tokens: {tokens}</button>*/}
        </div>
    );
}
