import { useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import "./pdf.css";
import { useDispatch, useSelector } from 'react-redux';
import { setPDFOpen } from '../../reducers/settings';
import { RootState } from '../../store';



function highlightPattern(text: string, pattern: string) {
    return text.replace(pattern, (value) => `<mark>${value}</mark>`);
}
interface PDFViewerProps {
    pageNum: number;
}
export default function PDFViewer({
    pageNum

}: PDFViewerProps) {
    const dispatch = useDispatch();
    const pdfOpen = useSelector(
        (state: RootState) => state.settings.pdfOpen
    );
    const [numPages, setNumPages] = useState<number>(139);
    const [pageNumber, setPageNumber] = useState<number>(pageNum);
    const [searchText, setSearchText] = useState('');
    const [pageHeight, setPageHeigth] = useState(0);
    const [pageWidth, setPageWidth] = useState(0);
    const pdfWrapperRef = useRef<HTMLDivElement>(null);


    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);

        setPageNumber(pageNum);
        setSearchText('Artículo 9');
        console.log("page:", pageNum);
    }
    const textRenderer = useCallback(
        (textItem: { str: any; }) => { highlightPattern(textItem.str, searchText); console.log("str:", textItem.str, "search:", searchText) },
        [searchText]
    );


    function changePage(offset: number) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    useEffect(() => {
        setPageNumber(pageNum);
    }, [pageNum]);

    useEffect(() => {
        const pdfWrapper = pdfWrapperRef.current;
        const resizeObserver = new ResizeObserver(entries => {
            const firstEntry = entries[0];
            if (firstEntry) {
                const height = firstEntry.contentRect.height;
                const width = firstEntry.contentRect.width;
                console.log("height:", height," width:", width);
                setPageHeigth(height);
                setPageWidth(width)
            }
        });
        if (pdfWrapper) {
            resizeObserver.observe(pdfWrapper);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);
    return (
        <div ref={pdfWrapperRef} className={pdfOpen ? "pdf-wrapper open" : "pdf-wrapper closed"}>
            <Document

                file={"/Reglamento-de-Transito-CDMX.pdf"}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} height={pageHeight} width={pageWidth} />
              <a href={process.env.PUBLIC_URL +"/Reglamento-de-Transito-CDMX.pdf" } target="_blank" rel="noreferrer"> <button
                    className="close-button"

                >
    ↓
                </button></a>
                <button
                    className="close-button"
                    onClick={() => dispatch(setPDFOpen(false))}
                >
                    ✕
                </button>
                <div className='page-controls'> <button
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                >
                    ◀
                </button>
                    <p>
                        {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
                    </p>

                    <button
                        type="button"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                    >
                        ▶
                    </button>
                </div>
            </Document>

        </div>
    );
}