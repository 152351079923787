interface PackageProps {
  price: number;

}

export const Package = ({ price, }: PackageProps) => {

  return (
    <div className="package">
      <span>{"hello"}</span>
      <button className="accent-button">
      
        999
      </button>
    </div>
  );
};
