import "./chat.css";
import { Message } from "../utils";



export default function UserResponse({ message, photoURL }: { message: Message, photoURL: string }) {
  const formattedDate: Date = message.timestamp.toDate();
  return (
    <div className={"chat-user-response__container"}>

      <div className="chat-user__response-bubble">
        <div className="chat-user__response-bubble-top">
          <div className="chat-details__photo"> <img alt="pfp" width={32} height={32} src={photoURL} /></div>
          <div>
          
          {formattedDate && <p className="chat-details__date">    {formattedDate.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: true  })}</p>}  <span className="roboto-medium">TU</span></div></div>
        <div className="chat-user__response-text">{message.content}</div>

      </div>

    </div>
  );
}
