import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import {
  setActiveThreadID,
  setActiveThreadMessages,
} from "../../reducers/settings";
import SignOutButton from "./signOutButton";
import { Thread } from "../utils";
import ThreadEntry from "./chatThreadsEntry";

interface ChatThreadsHistoryProps {
  name: string | undefined;
  photoURL: string | undefined;
  children?: React.ReactNode;
  handleThreadChange: (message: string) => void;
}
export default function ChatThreadsHistory({
  name,
  photoURL,
  handleThreadChange,
}: ChatThreadsHistoryProps) {
  const dispatch = useDispatch();
  const threads = useSelector((state: RootState) => state.settings.threads);
  const activeThreadID = useSelector(
    (state: RootState) => state.settings.activeThreadID
  );

  const historyOpen = useSelector(
    (state: RootState) => state.settings.historyOpen
  );

  const handleNewThread = () => {
    console.log("new thread");
    dispatch(setActiveThreadID(null));
    dispatch(setActiveThreadMessages([]));
  };

  return (
    <div
      className={"chat-screen__threads " + (historyOpen ? "open" : "closed")}
    >
      <div className={"chat-screen__threads-button-wrapper"}>
        <button className="accent-button" onClick={handleNewThread}>
          Nueva Conversación
        </button>
      </div>
      <div className={"chat-screen__threads-inner-top "}>
        <div className={"chat-screen__threads-inner-top-scroll-wrapper "}>
          <div className={"chat-screen__threads-inner-top-scroll "}>
            {threads &&
              threads.map(function (data: Thread, index) {
                const formattedDate: Date = data.createdAt.toDate();

                return (
                  <li
                    key={`thread-${index}`}
                    className={"chat-screen__thread "}
                  >
                    <button
                      className={`chat-screen__thread-button ${
                        activeThreadID === data.id ? "active" : ""
                      }`}
                      onClick={() => handleThreadChange(data.id)}
                    >
                  <ThreadEntry date={formattedDate.toLocaleString("es-MX", { day: '2-digit', month: 'long' })} id={data.id}/>
                    </button>
                  </li>
                );
              })}
          </div>
        </div>
      </div>
      <div className={"chat-screen__threads-inner-bottom "}>
        <div className={"chat-screen__threads-button-wrapper"}>
          {/* <a href="/account" className="chat-screen__account-link"> <img className="pfp" src={photoURL} alt="pfp" /><span>{name}</span></a>*/}
          <span className="chat-screen__account-link">
            {" "}
            <img className="pfp" src={photoURL} alt="pfp" />
            <span>{name}</span>
          </span>
          <SignOutButton />
        </div>

        {/* <button className="accent-button" onClick={() => dispatch(togglePackagesOpen())}> Comprar Tokens</button> */}
      </div>
    </div>
  );
}
