
import "./packages.css";

import { togglePackagesOpen } from "../../reducers/settings";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import Packages from "./packages";


interface PackagesScreenProps {
  name: string | undefined;
}
export default function PackagesScreen({ name }: PackagesScreenProps) {
  const dispatch = useDispatch();
  const packagesOpen = useSelector(
    (state: RootState) => state.settings.packagesOpen
  );
  return packagesOpen ? (
    <div
      className="packages-screen__wrapper"
      onClick={() => dispatch(togglePackagesOpen())}
    >
      <div className="packages-screen" onClick={(e) => e.stopPropagation()}>
        <div className="packages-screen__top-row">
          <button
            className="close-button"
            onClick={() => dispatch(togglePackagesOpen())}
          >
            ✕
          </button>
        </div>


        <Packages />
        <a href="/terms">Terminos y Condiciones</a>
      </div>
    </div>
  ) : null;
}
