import { useState, useEffect } from "react";
import "./landing.css";

interface LandingSpinnerProps {
  loaded: boolean;
}

const LandingSpinner = ({ loaded }: LandingSpinnerProps) => {
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (loaded) {
      timeoutId = setTimeout(() => {
        setIsDone(true);
      }, 300);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [loaded]);

  return (
    <div className={`landing-spinner-wrapper ${loaded ? "loaded" : ""} ${isDone ? "done" : ""}`}>
      <div className="fill-font-wrapper">
        <span className="fill-font roboto-medium">TRANSITO.PRO</span>
        <span className="fill-font roboto-medium">TRANSITO.PRO</span>
      </div>
    </div>
  );
};

export default LandingSpinner;
