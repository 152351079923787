import "./chat.css";

import { useRef, useEffect, useState } from "react";
import { Message } from "../utils";
import Markdown from "react-markdown";

export default function LlmResponse({
  message,
  stream,
}: {
  message: Message;
  stream?: string | null;
}) {
  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);
  const [answer, setAnswer] = useState<string>("");

  useEffect(() => {
    if(stream){
      setAnswer(stream);
    }
   

  }, [stream]);

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message.content]);


  const formattedDate: Date = message.timestamp.toDate();

  return (
    <div className={"chat-bot-response__container"}>
      <div className="chat-bot__response-bubble">
        <div className="chat-bot__response-bubble-top">
          <div className="chat-details__photo llm">
            {" "}
            <svg
            id="transitor-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 107.3 114"
          >
            <path d="m30.54,94.9c0,1.75,1.42,3.18,3.18,3.18h32.68c1.75,0,3.18-1.42,3.18-3.18s-1.42-3.18-3.18-3.18h-32.68c-1.75,0-3.18,1.42-3.18,3.18Z" />
            <circle cx="30.54" cy="77.07" r="7.62" />
            <circle cx="69.58" cy="77.07" r="7.62" />
            <path d="m97.58,64.42h-4.96c-.18,0-.36.02-.53.06v-.06h-4.01v36.76c0,4.86-3.95,8.81-8.81,8.81H20.84c-4.86,0-8.81-3.95-8.81-8.81v-44.91c0-4.86,3.95-8.81,8.81-8.81v-.78c-1.18-.65-1.93-1.77-2.24-3.02-6,1.07-10.58,6.31-10.58,12.61v8.21c-.17-.04-.35-.06-.53-.06H2.54c-1.4,0-2.54,1.14-2.54,2.54v20.23c0,1.4,1.14,2.54,2.54,2.54h4.96c.18,0,.36-.02.53-.06v11.52c0,7.07,5.75,12.82,12.82,12.82h58.43c7.07,0,12.82-5.75,12.82-12.82v-11.52c.17.04.35.06.53.06h4.96c1.4,0,2.54-1.14,2.54-2.54v-20.23c0-1.4-1.14-2.54-2.54-2.54Z" />
            <path d="m69.57,2.17c-29.62-6.67-44.89,3.6-46.32,9.95-1.43,6.35,4.79,9.97,4.79,9.97l-.64,12.14c-.28.03-.56.05-.84.08-1.07.11-2.05.65-2.72,1.5-.67.84-.97,1.92-.84,2.99.26,2.06,3.09,12.7,33.9,19.64,8.03,1.81,15.1,2.72,21,2.72,13.24,0,17.16-4.55,18.05-5.94.58-.91.77-2.01.52-3.06-.24-1.05-.9-1.96-1.82-2.52-.24-.15-.49-.29-.73-.44l4.63-11.24s7.17-.61,8.6-6.95c1.43-6.35-7.98-22.17-37.59-28.83Zm-12.77,12.53l10.16-.94,8.78,5.21c-3.37,14.96-13.42,15.41-13.42,15.41,0,0-8.89-4.71-5.52-19.67Zm-18.45,21.79v1.19-1.19s0,0,0,0Zm39.56,20.64c-4.95,0-11.5-.69-20.12-2.63-29.62-6.67-30.8-16.21-30.8-16.21,4.06-.43,7.87-.6,11.36-.6,13.79,0,22.62,2.7,22.62,2.7,0,0,14.34,2.12,31.6,12.67,0,0-2.6,4.08-14.66,4.08Z" />
          </svg>
          </div>
          <div>
            {message.timestamp && (
              <p className="chat-details__date">
                {formattedDate.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: true  })}
              </p>
            )}{" "}
            <span className="roboto-medium">TRANSITO.PRO</span>
          </div>
        </div>
        <div className="chat-bot__response-text">
          <span>
            {message.role === "transitor1" && "Pregunta masajeada: "}
            {message.role === "transitor2" && "Articulos encontrados: "}
            {message.role === "error visible" &&
              ((message.content === "Error: rewrittenQuery is null" &&
                "Hubo un error, por favor escribe tu pregunta de nuevo. ") ||
                (message.content === "Error: Exceeded context retry count" &&
                  "Lo siento, estoy teniendo problemas, vuelve a intentar en unos minutos ") ||
                  (message.content === "Error: Exceeded answer retry count" &&
                  "Lo siento, estoy teniendo problemas, vuelve a intentar en unos minutos ") ||
                  (message.content === "Error: Question out of scope" &&
                  "Parece que tu pregunta sale del alcance del reglamento de tránsito de la CDMX. Si tienes alguna duda sobre el reglamento de tránsito estoy aquí para ayudarte.") ||
                "Hubo un error, por favor intenta de nuevo")}

            {message.role === "error visible" ? (
              <div className="error-type">{stream || message.content}</div>
            ) : (
            stream?<Markdown>{stream}</Markdown>:<Markdown>{message.content}</Markdown>
            )}
          </span>
          <div ref={endOfMessagesRef} />
        </div>
      </div>
    </div>
  );
}
