import { cloudFunctionUrl } from "../components/utils";

export async function getContext(question: string): Promise<number[] | undefined> {



  try {
    // Make a POST request to the Cloud Function
    const response = await fetch(cloudFunctionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ prompt: question, action: "transitor2" })
    });

    // Check for successful response
    if (!response.ok) {
      throw new Error(`Cloud Function returned status: ${response.status}`);
    }

    // Handle chunked response
    let responseData1 = "";
    if (!response.body) throw new Error("No response body");
    const reader = response.body.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      responseData1 += new TextDecoder().decode(value);

    }


    const data1 = responseData1;

/*
    // Make a POST request to the Cloud Function
    const response2 = await fetch(cloudFunctionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ prompt: question, action: "transitor2" })
    });

    // Check for successful response
    if (!response2.ok) {
      throw new Error(`Cloud Function returned status: ${response.status}`);
    }

   // Handle chunked response
   let responseData2 = "";
   if (!response2.body) throw new Error("No response body");
   const reader2 = response2.body.getReader();
   while (true) {
     const { done, value } = await reader2.read();
     if (done) {
       break;
     }
     responseData2 += new TextDecoder().decode(value);

   }


   const data2 = responseData2;

    // Make a POST request to the Cloud Function
    const response3 = await fetch(cloudFunctionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ prompt: question, action: "transitor2" })
    });

    // Check for successful response
    if (!response3.ok) {
      throw new Error(`Cloud Function returned status: ${response.status}`);
    }

      // Handle chunked response
   let responseData3 = "";
   if (!response3.body) throw new Error("No response body");
   const reader3 = response3.body.getReader();
   while (true) {
     const { done, value } = await reader3.read();
     if (done) {
       break;
     }
     responseData3 += new TextDecoder().decode(value);

   }


   const data3 = responseData3;*/

    const relevantContext1 = data1;


  //  const relevantContext2 = data2;


  //  const relevantContext3 = data3;

    console.log("articulos relevantes A: ", relevantContext1);
  //  console.log("articulos relevantes B: ", relevantContext2);
 //   console.log("articulos relevantes C: ", relevantContext3);


    const contextsArray = [
      JSON.parse(relevantContext1).map(Number),
    //  JSON.parse(relevantContext2).map(Number),
    //  JSON.parse(relevantContext3).map(Number),
    ];



    //get answer context
    const relevantArticles = Array.from(new Set(contextsArray.flat()));

    return relevantArticles;
  } catch (error) {
    console.error("An error occurred getting context:", error);
    return undefined; // or handle the error as needed
  }
}




