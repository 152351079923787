import { cloudFunctionUrl } from "../components/utils";

export async function reWriteQuery(question: string): Promise<string | undefined> {
   

  

 try {
  // Make a POST request to the Cloud Function
  const response = await fetch(cloudFunctionUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Transfer-Encoding": "chunked"
    },
    body: JSON.stringify({ prompt: question, action: "transitor1" }),
  });

  // Check for successful response
  if (!response.ok) {
    throw new Error(`Cloud Function returned status: ${response.status}`);
  }

  // Handle chunked response
  let responseData = "";
  if(!response.body)throw new Error("No response body");
  const reader = response.body.getReader();
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    responseData += new TextDecoder().decode(value);
    console.log("response1:",responseData)
    
  }
  

 const data = responseData;

     
   const formalQuestion = data ;     
    console.log("pregunta formal: ", formalQuestion);        
    return formalQuestion;            
} catch (error) {
  console.error("An error occurred:", error);
  return undefined;
}
}




