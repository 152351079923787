import {
  getAuth,
} from "firebase/auth";
import { app } from "../../firebase-setup/firebase";
  const auth = getAuth(app);
export default function SignOutButton() {

  const logout = () => {
 
    auth.signOut().then(() => {
   
    });
  }



  return <button onClick={logout} className={"sign-out-button"}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" /></svg></button>;
}
