import { cloudFunctionUrl } from "../components/utils";

export async function getAnswer(question: string, context: {}): Promise<string | undefined> {

  console.log("getAnswer ");  

    try {
      // Make a POST request to the Cloud Function
      const response = await fetch(cloudFunctionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Transfer-Encoding": "chunked"
        },
          body: JSON.stringify({ prompt: question, action:"transitor3", context})
      });
  
      // Check for successful response
      if (!response.ok) {
        throw new Error(`Cloud Function returned status: ${response.status}`);
      }


  // Handle chunked response
  let responseData = "";
  if(!response.body)throw new Error("No response body");
  const reader = response.body.getReader();
  console.log("reader: ", response.body);  
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    responseData += new TextDecoder().decode(value);
    console.log("response3:",responseData)
    
  }
  

 const data = responseData;

     
   const answer = data ;     
    console.log("respuesta: ", answer);        
    return answer;
    } catch (error) {
      console.error("An error occurred getting answer:", error);
      return undefined; // or handle the error as needed
    }
  }

