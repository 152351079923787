import { useState, ChangeEvent, FormEvent } from 'react';
import {articlePages } from "../utils";
import { useDispatch } from 'react-redux';
import { setPDFOpen, setPDFPage } from '../../reducers/settings';


interface relevantArticlesProps {

articles:never[]

}


    const RelevantArticles: React.FC<relevantArticlesProps> = (props) => {
    const dispatch = useDispatch();

   

    return (
   
            <>
{props.articles
  .slice() // Create a shallow copy of the array to avoid mutating the original
  .sort((a, b) => a - b) // Sort the copied array
  .map((number, index) => {
    return articlePages[
      `Artículo ${number}.` as keyof typeof articlePages
    ] ? (
      <button
        style={{ margin: "0 1rem 0 0" }}
        className={"chat-container__article-button "}
        key={index}
        onClick={() => {
          dispatch(setPDFOpen(true));
          dispatch (setPDFPage (
            articlePages[
              `Artículo ${number}.` as keyof typeof articlePages
            ][0]
          ));
        }}
      >
        Articulo {number}
      </button>
    ) : null;
  })}
 
</>
   

        
    );
};

export default RelevantArticles;


