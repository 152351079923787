import { ChangeEvent, useEffect, useRef } from "react";

interface FeedbackContainerProps {

    saveFeedbackToFirestore: (feedback: string) => void;
    setFeedbackInputValue: (feedback: string) => void;
    setFeedbackSentiment: (feedback: string) => void;
    feedbackSentiment:string;
    feedbackInputValue:string;

}

const FeedbackContainer = ({  saveFeedbackToFirestore,setFeedbackInputValue,setFeedbackSentiment, feedbackInputValue, feedbackSentiment }: FeedbackContainerProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
  
    const handleFeedbackInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setFeedbackInputValue(event.target.value);
    };
  
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.value = feedbackInputValue;
      }
    }, [feedbackInputValue]);
  
    return (
      <div className='feedback-container'>
        <span  className='feedback-title'>Ayudanos calificando la respuesta:</span>
        <div>
        <button className={`feedback-thumbs up ${feedbackSentiment==="bueno"?"active":""}`}  onClick={() => setFeedbackSentiment("bueno")}>👍</button>
        <button className={`feedback-thumbs down ${feedbackSentiment==="malo"?"active":""}`}  onClick={() => setFeedbackSentiment("malo")}>👎</button>
        </div> <input className="feedback-input" ref={inputRef} placeholder="Comentario opcional" type="text" onChange={handleFeedbackInputChange} />
  
        <button className='accent-button'onClick={() => saveFeedbackToFirestore(feedbackSentiment+"-"+feedbackInputValue)}>Enviar</button>
       
      </div>
    );
  };

  export default FeedbackContainer;