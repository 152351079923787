import React, { FormEvent, useEffect, useRef, useState } from "react";
import "./chat.css";
import ChatTitle from "./chatTitle";
import LlmResponse from "./llmResponse";
import UserInput from "./userInput";
import UserResponse from "./userResponse";
import { pdfjs } from "react-pdf";
import PDFViewer from "./pdfViewer";
import { setPDFOpen } from "../../reducers/settings";
import { Message, articlePages } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Timestamp } from "firebase/firestore";
import RelevantArticles from "./relevantArticles";

const messages: Message[] = [
  {
    content: "cuales son los articulos mas relevantes de la ley de transito?",
    role: "user",
    timestamp:
      "Timestamp(seconds=1711644843, nanoseconds=406000000)" as unknown as Timestamp,
  },
  {
    timestamp:
      "Timestamp(seconds=1711644843, nanoseconds=406000000)" as unknown as Timestamp,
    content:
      "Los **artículos más relevantes de la Ley de Tránsito** que debes tener en cuenta son principalmente los que se refieren a la preferencia de paso y sanciones en casos de infracción. Aquí te resumo algunos:\n\n- **Artículo 6:** Establece que los peatones tienen preferencia de paso sobre los vehículos. Si no cedes el paso a un peatón, la multa puede ser equivalente a 10, 15 o 20 veces la Unidad de Medida y Actualización vigente y te pueden sumar tres puntos a tu licencia para conducir. En caso de captarse la infracción a través de sistemas tecnológicos, se sancionará con un punto a la matrícula vehicular.\n\n- **Artículo IX:** En las glorietas, los vehículos dentro de la misma tienen preferencia de paso. Aquellos que no respeten esta norma pueden recibir una multa similar a la de no ceder el paso a peatones.\n\n- **Artículo XI:** Este artículo habla sobre la obligación de dar prioridad a vehículos de emergencia cuando circulen con las señales luminosas y audibles encendidas. La multa por no acatar esta norma puede ser igual a la establecida en los artículos 6 y IX.\n\n- **Artículo III:** Refuerza que los vehículos de emergencia tienen preferencia de paso al circular con las señales luminosas o audibles encendidas. La infracción a esta norma se castiga con una multa equivalente a 20, 25, o 30 veces la Unidad de Medida y Actualización vigente, sumando tres puntos a tu licencia para conducir o, si la infracción se detecta por sistemas tecnológicos, se deben añadir un punto a la matrícula vehicular.\n\nRecuerda que el respeto a las leyes de tránsito es fundamental para la seguridad de todos en la vía. Las multas y puntos en la licencia o matrícula son consecuencias de no acatar las normas, pero lo más importante es evitar accidentes. Si tienes más preguntas sobre la Ley de Tránsito, no dudes en preguntarme.",
    role: "assistant",
  },
];

const tokens = 20;
function removeDuplicates(arr: any[]) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}
// Worker for pdf.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface ChatScreenProps {
  photoURL: string | undefined;
  handleSubmitMessage: (message: string) => void;
  submitted: boolean;
  children?: React.ReactNode;
}

const ChatScreen: React.FC<ChatScreenProps> = (props) => {
  //const messages = test;
  const dispatch = useDispatch();

  const activeThreadMessages = useSelector(
    (state: RootState) => state.settings.activeThreadMessages
  ) as Message[];
  const streamedAnswer = useSelector(
    (state: RootState) => state.settings.streamedAnswer
  );

  const relevantArticles = useSelector(
    (state: RootState) => state.settings.relevantArticles
  );
  const user = useSelector((state: RootState) => state.settings.user);
  const pageNumber = useSelector((state: RootState) => state.settings.pdfPage);

  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);

  // const tokens = 0;
  const [status, setStatus] = useState("awaiting_message");
  const [localMessages, setLocalMessages] = useState<Message[]>(messages); //SET THIS

  const [debugCheck, setDebugCheck] = useState(false);

  const handleNoTokens = () => {
    setLocalMessages([
      {
        content:
          "Asi no se va a poder joven, ya no tiene tokens. Ayudeme a ayudarlo, compre un paquete y sigamos conversando:",
        role: "assistant",
        timestamp: Timestamp.now(),
      },
    ]);
  };



  const handleCheckChange = () => {
    setDebugCheck(!debugCheck);
  };

  const handleSpinnerMessage = (role: string) => {
    let message = "";
    switch (role) {
      case "error":
        message = "";
        break;
      case "error visible":
        message = "";
        break;
      case "userFeedback":
        message = "";
        break;
      case "user":
        message = "Analizando pregunta";
        break;
      case "transitor1":
        message = "Buscando en reglamento de transito";
        break;
      case "transitor2":
        message = "Sintetizando respuesta";
        break;
      case "transitor3":
        message = "";
        break;
      case "transitor":
        message = "";
        break;
      default:
        throw new Error(`Invalid role: ${role}`);
    }

    return message;
  };

  useEffect(() => {
    console.log("chat: ", activeThreadMessages);
    console.log("user: ", user?.uid);

    // findArticleMentions();
   // setLocalMessages(activeThreadMessages);
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeThreadMessages]);

  return (
    <div className="chat-container__outer">
      <div className="chat-container">
        {(user?.uid === "RbDtDQ4NijXri129KjFyduGDt7f1" ||
          "HYAhidJdvZYhL190QEJwgcz18iM2") && (
          <div className="bug-check">
            <label>
              🐞
              <input
                type="checkbox"
                checked={debugCheck}
                onChange={handleCheckChange}
              />
            </label>
          </div>
        )}
        <PDFViewer pageNum={pageNumber} />
        <div className="chat-container__history">
          <div className="chat-container__inner">
           <LlmResponse  message={ {
        content:
          "Bienvenido. Soy un agente virtual entrenado con el reglamento de tránsito de la CDMX, si tienes alguna duda sobre el reglamento de tránsito estoy aquí para ayudarte.",
        role: "transitor",
        timestamp: Timestamp.now(),
      }} />
            {debugCheck &&
              activeThreadMessages.length > 0 &&
              activeThreadMessages.map((message: Message, i) => {
                if (
                  message.role === "user" ||
                  message.role === "userFeedback"
                ) {
                  return (
                    <UserResponse
                      key={"u-" + i}
                      message={message}
                      photoURL={props.photoURL ? props.photoURL : ""}
                    />
                  );
                } else
                  return (
                    <>
                      <LlmResponse key={"t-" + i} message={message} />
                      {message.role === "transitor" &&
                        i === activeThreadMessages.length - 1 &&
                        props.children}
                    </>
                  );
              })}
            {!debugCheck &&
              activeThreadMessages.length > 0 &&
              activeThreadMessages.map((message: Message, i) => {
                if (message.role === "user") {
                  return (
                    <UserResponse
                      key={"u-" + i}
                      message={message}
                      photoURL={props.photoURL ? props.photoURL : ""}
                    />
                  );
                } else if (
                 
                  message.role === "error visible"
                ) {
                  return (
                    <>
                      <LlmResponse
                        key={"t-" + i}
                        message={message}
                      
                      />
                   
                    </>
                  );
                }else if (
                  message.role === "transitor" 
                
                ) {
                  return (
                    <>
                      <LlmResponse
                        key={"t-" + i}
                        message={message}
                      
                      />
                          <div className="chat-container__articles">
                          { activeThreadMessages[i-1].role ==="transitor2"&&<RelevantArticles articles={JSON.parse("[" + activeThreadMessages[i-1].content + "]")}/>}
      </div>
                    
                      {i === activeThreadMessages.length - 1 && props.children}
                    </>
                  );
                }

              })}

            {activeThreadMessages[activeThreadMessages.length - 1]?.role && (
              <span className="spinnerMessage">
                {handleSpinnerMessage(
                  activeThreadMessages[activeThreadMessages.length - 1]?.role
                )}
              </span>
            )}
          </div>
          <div ref={endOfMessagesRef} />
        </div>
    
        <div className={"chat-container__status " + status}></div>
        <div className="input-wrapper">
        <UserInput
          handleSubmitMessage={props.handleSubmitMessage}
          submitted={props.submitted}
        />
        </div>
      </div>
    </div>
  );
};

export default ChatScreen;
